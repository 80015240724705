import React, { useState, useEffect } from 'react'
import { FormattedMessage } from 'react-intl'
import { navigate } from 'gatsby'
import { useForm } from 'react-hook-form'

const GATEWAY_URL =
  process.env.NODE_ENV === 'development'
    ? 'https://7viw90q0p2.execute-api.us-east-1.amazonaws.com/default/registerNewStudent'
    : 'https://ns8i2q2pr6.execute-api.ap-east-1.amazonaws.com/default/registerStudent'

const required = 'This is a required question'
const birth_range = 'Must be a number between 2000 and 2100'
const phone_range = 'Must be 8 digits'
const email_address = 'Must be a valid email address'
const skypeid = 'No space is allowed'

const CourseForm = () => {
  const [submitted, setSubmitted] = useState(false)
  const {
    register,
    unregister,
    handleSubmit,
    setError,
    reset,

    formState: { isSubmitting, errors },

    setValue,
    clearError,
    watch,
    getValues,
  } = useForm()

  // for conditional questions
  let source = watch('source')
  let course = watch('course')

  const onSubmit = async (data) => {
    try {
      await fetch(GATEWAY_URL, {
        method: 'POST',
        mode: 'cors',
        cache: 'no-cache',
        body: JSON.stringify(data),
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
        },
      })
      setSubmitted(true)
      reset()
      navigate('/confirm')
    } catch (error) {
      setError(
        'submit',
        'submitError',
        `Oops! There seems to be an issue! ${error.message}`
      )
    }
  }

  const showSubmitError = (msg) => <p className="msg-error">{msg}</p>

  const showThankYou = (
    <div className="section has-background-white-bis">
      <div className="title has-text-centered">
        <FormattedMessage id="form--confirm-title" />
      </div>
      <div className="subtitle has-text-centered">
        <FormattedMessage id="form--confirm-subtitle" />
      </div>
      {/* <button type="button" onClick={() => setSubmitted(false)}>
        Send another message?
      </button> */}
    </div>
  )

  const showForm = (
    <div className="card">
      <div className="card-content">
        <form onSubmit={handleSubmit(onSubmit)} method="post">
          <div className="field">
            <label className="label">
              <FormattedMessage id="form--course-question" />
              <span className="has-text-danger">*</span>
            </label>
            <div className="control">
              <label className="radio">
                <input
                  {...register('course', { required })}
                  id="course-1"
                  type="radio"
                  value="一對一英文試堂"
                />{' '}
                <FormattedMessage id="form--course-option1" />
              </label>
            </div>
            <div className="control">
              <label className="radio">
                <input
                  {...register('course', { required })}
                  id="course-2"
                  type="radio"
                  value="英語恆常小課室（小組）"
                />{' '}
                <FormattedMessage id="form--course-option2" />
              </label>
            </div>
            {errors.course && (
              <div className="help is-danger">{errors.course.message}</div>
            )}
          </div>

          <div className="field">
            <label className="label">
              <FormattedMessage id="form--email-question" />
              <span className="has-text-danger">*</span>
            </label>
            <div className="control">
              <input
                className={'input ' + (errors.email ? 'is-danger' : '')}
                type="email"
                {...register('email', { required, pattern: /^\S+@\S+\.\S+$/i })}
                aria-label="email address"
                placeholder="Smarty@gmail.com"
              />
              <div className="help has-text-grey">
                <FormattedMessage id="form--email-hint" />
              </div>
              {errors.email && (
                <div className="help is-danger">{errors.email.message}</div>
              )}
              {errors.email?.type === 'pattern' && (
                <div className="help is-danger">{email_address}</div>
              )}
            </div>
          </div>

          <div className="field">
            <label className="label">
              <FormattedMessage id="form--nickname-question" />
              <span className="has-text-danger">*</span>
            </label>
            <div className="control">
              <input
                className={'input ' + (errors.nickname ? 'is-danger' : '')}
                type="text"
                {...register('nickname', { required })}
                aria-label="nickname"
                placeholder="John"
              />
              <div className="help has-text-grey">
                <FormattedMessage id="form--nickname-hint" />
              </div>
              {errors.nickname && (
                <div className="help is-danger">{errors.nickname.message}</div>
              )}
            </div>
          </div>

          <div className="field">
            <label className="label">
              <FormattedMessage id="form--name-question" />
              <span className="has-text-danger">*</span>
            </label>
            <div className="control">
              <input
                className={'input ' + (errors.name ? 'is-danger' : '')}
                type="text"
                {...register('name', { required })}
                aria-label="family name"
                placeholder="Chan"
              />
              <div className="help has-text-grey">
                <FormattedMessage id="form--name-hint" />
              </div>
              {errors.name && (
                <div className="help is-danger">{errors.name.message}</div>
              )}
            </div>
          </div>

          <div className="field">
            <label className="label">
              <FormattedMessage id="form--gender-question" />
              <span className="has-text-danger">*</span>
            </label>
            <div className="control">
              <label className="radio">
                <input
                  {...register('gender', { required })}
                  id="gender-m"
                  type="radio"
                  value="M"
                />{' '}
                <FormattedMessage id="form--gender-option1" />
              </label>
            </div>
            <div className="control">
              <label className="radio">
                <input
                  {...register('gender', { required })}
                  id="gender-f"
                  type="radio"
                  value="F"
                />{' '}
                <FormattedMessage id="form--gender-option2" />
              </label>
              {errors.gender && (
                <div className="help is-danger">{errors.gender.message}</div>
              )}
            </div>
          </div>

          <div className="field">
            <label className="label">
              <FormattedMessage id="form--birth-question" />
              <span className="has-text-danger">*</span>
            </label>
            <div className="control">
              <input
                className={'input ' + (errors.birth ? 'is-danger' : '')}
                type="number"
                {...register('birth', {
                  required,
                  min: 2000,
                  max: 2100,
                })}
                aria-label="birth"
                placeholder="2010"
                min="2000"
                max="2100"
              />
              <div className="help has-text-grey"></div>
              {errors.birth && (
                <div className="help is-danger">{errors.birth.message}</div>
              )}
              {errors.birth?.type === 'min' && (
                <div className="help is-danger">{birth_range}</div>
              )}
              {errors.birth?.type === 'max' && (
                <div className="help is-danger">{birth_range}</div>
              )}
            </div>
          </div>

          <div className="field">
            <label className="label">
              <FormattedMessage id="form--grade-question" />
              <span className="has-text-danger">*</span>
            </label>
            <div className="control">
              <select
                className={'input ' + (errors.grade ? 'is-danger' : '')}
                {...register('grade', { required })}
              >
                <option disable="true" value="">
                  Please choose
                </option>
                <option value="K1">K1</option>
                <option value="K2">K2</option>
                <option value="K3">K3</option>
                <option value="P1">P1</option>
                <option value="P2">P2</option>
                <option value="P3">P3</option>
                <option value="P4">P4</option>
                <option value="P5">P5</option>
                <option value="P6">P6</option>
                <option value="F1">F1</option>
                <option value="F2">F2</option>
                <option value="F3">F3</option>
                <option value="F4">F4</option>
                <option value="F5">F5</option>
                <option value="F6">F6</option>
              </select>
              {errors.grade && (
                <div className="help is-danger">{errors.grade.message}</div>
              )}
            </div>
          </div>

          <div className="field">
            <label className="label">
              <FormattedMessage id="form--phone-question" />
              <span className="has-text-danger">*</span>
            </label>
            <div className="control">
              <input
                className={'input ' + (errors.phone ? 'is-danger' : '')}
                type="number"
                {...register('phone', {
                  required,
                  minLength: 8,
                  maxLength: 8,
                  min: 10000000,
                })}
                aria-label="phone"
                placeholder="57429598"
                min="10000000"
              />
              <div className="help has-text-grey">
                <FormattedMessage id="form--phone-hint" />
              </div>
              {errors.phone && (
                <div className="help is-danger">{errors.phone.message}</div>
              )}
              {(errors.phone?.type === 'minLength' ||
                errors.phone?.type === 'maxLength') && (
                <div className="help is-danger">{phone_range}</div>
              )}
            </div>
          </div>

          <div className="field">
            <label className="label">
              <FormattedMessage id="form--whatsapp-question" />
            </label>
            <div className="checkbox">
              <input
                type="checkbox"
                {...register('whatsapp')}
                aria-label="whatsapp"
              />
              <div className="help has-text-grey">
                <FormattedMessage id="form--whatsapp-hint" />
              </div>
              {errors.whatsapp && (
                <div className="help is-danger">{errors.name.whatsapp}</div>
              )}
            </div>
          </div>

          <div className="field">
            <label className="label">
              <FormattedMessage id="form--parent-question" />
            </label>
            <div className="text">
              <input
                className={'input ' + (errors.parent ? 'is-danger' : '')}
                type="text"
                {...register('parent')}
                aria-label="parent"
                placeholder="Smarty"
              />
              <div className="help has-text-grey">
                <FormattedMessage id="form--parent-hint" />
              </div>
            </div>
          </div>

          <div className="field">
            <label className="label">
              <FormattedMessage id="form--remarks-question" />
            </label>
            <div className="text">
              <input
                className={'input ' + (errors.remarks ? 'is-danger' : '')}
                type="text"
                {...register('remarks')}
                aria-label="remarks"
              />
            </div>
          </div>
          <div className="help has-text-grey">
            <FormattedMessage id="form--remarks-hint" />
          </div>

          <div className="field">
            <label className="label">
              <FormattedMessage id="form--code-question" />
            </label>
            <div className="control">
              <input
                className="input"
                type="text"
                {...register('code')}
                aria-label="code"
              />
            </div>
          </div>

          <div className="field">
            <label className="label">
              <FormattedMessage id="form--source-question" />
              <span className="has-text-danger">*</span>
            </label>
            <div className="control">
              <label className="radio">
                <input
                  {...register('source', { required })}
                  id="source-google"
                  type="radio"
                  value="Google 搜尋結果"
                />{' '}
                <FormattedMessage id="form--source-option1" />
              </label>
            </div>
            <div className="control">
              <label className="radio">
                <input
                  {...register('source', { required })}
                  id="source-youtube"
                  type="radio"
                  value="Youtube 廣告"
                />{' '}
                <FormattedMessage id="form--source-option2" />
              </label>
            </div>
            <div className="control">
              <label className="radio">
                <input
                  {...register('source', { required })}
                  id="source-web"
                  type="radio"
                  value="網站廣告欄"
                />{' '}
                <FormattedMessage id="form--source-option3" />
              </label>
            </div>
            <div className="control">
              <label className="radio">
                <input
                  {...register('source', { required })}
                  id="source-gmail"
                  type="radio"
                  value="Gmail 廣告"
                />{' '}
                <FormattedMessage id="form--source-option4" />
              </label>
            </div>
            <div className="control">
              <label className="radio">
                <input
                  {...register('source', { required })}
                  id="source-refer"
                  type="radio"
                  value="別人介紹"
                />{' '}
                <FormattedMessage id="form--source-option5" />
              </label>
            </div>
            <div className="control">
              <label className="radio">
                <input
                  {...register('source', { required })}
                  id="source-facebook"
                  type="radio"
                  value="Facebook/Instagram"
                />{' '}
                <FormattedMessage id="form--source-option6" />
              </label>
            </div>
            <div className="control">
              <label className="radio">
                <input
                  {...register('source', { required })}
                  id="source-others"
                  type="radio"
                  value="其他"
                />{' '}
                <FormattedMessage id="form--source-option7" />
              </label>
            </div>
            {errors.source && (
              <div className="help is-danger">{errors.source.message}</div>
            )}
          </div>

          {source === 'Google 搜尋結果' && (
            <div className="field">
              <label className="label">
                <FormattedMessage id="form--keyword-question" />
                <span className="has-text-danger">*</span>
              </label>
              <div className="control">
                <input
                  className={'input ' + (errors.keyword ? 'is-danger' : '')}
                  type="text"
                  {...register('keyword', { required })}
                  aria-label="keyword searched"
                />
                <div className="help has-text-grey">
                  <FormattedMessage id="form--keyword-hint" />
                </div>
                {errors.keyword && (
                  <div className="help is-danger">{errors.keyword.message}</div>
                )}
              </div>
            </div>
          )}

          {source === 'Youtube 廣告' ||
            (source === '網站廣告欄' && (
              <div className="field">
                <label className="label">
                  <FormattedMessage id="form--display-question" />
                  <span className="has-text-danger">*</span>
                </label>
                <div className="control">
                  <input
                    className={'input ' + (errors.display ? 'is-danger' : '')}
                    type="text"
                    {...register('display', { required })}
                    aria-label="display location"
                  />
                  <div className="help has-text-grey">
                    <FormattedMessage id="form--display-hint" />
                  </div>
                  {errors.display && (
                    <div className="help is-danger">
                      {errors.display.message}
                    </div>
                  )}
                </div>
              </div>
            ))}

          {source === '別人介紹' && (
            <div className="field">
              <label className="label">
                <FormattedMessage id="form--referrer-question" />
                <span className="has-text-danger">*</span>
              </label>
              <div className="control">
                <input
                  className={'input ' + (errors.referrer ? 'is-danger' : '')}
                  type="text"
                  {...register('referrer', { required })}
                  aria-label="referrer information"
                />
                <div className="help has-text-grey">
                  <FormattedMessage id="form--referrer-hint" />
                </div>
                {errors.referrer && (
                  <div className="help is-danger">
                    {errors.referrer.message}
                  </div>
                )}
              </div>
            </div>
          )}

          {source === '其他' && (
            <div className="field">
              <label className="label">
                <FormattedMessage id="form--other-question" />
                <span className="has-text-danger">*</span>
              </label>
              <div className="control">
                <input
                  className={'input ' + (errors.other ? 'is-danger' : '')}
                  type="text"
                  {...register('other', { required })}
                  aria-label="other source"
                />
                {errors.other && (
                  <div className="help is-danger">{errors.other.message}</div>
                )}
              </div>
            </div>
          )}
          <br />

          <div className="control">
            <button
              id="submit-button"
              type="submit"
              className="button is-primary"
              disabled={isSubmitting}
            >
              <FormattedMessage id="form--button" />
            </button>
          </div>
        </form>
      </div>
    </div>
  )

  return (
    <div id="registration-form">
      <br />
      <br />
      <br />
      <h2>
        <FormattedMessage id="form--heading" />
      </h2>
      <div className="help has-text-grey">
        <FormattedMessage id="form--heading-hint1" />
      </div>
      <div className="help has-text-grey">
        <FormattedMessage id="form--heading-hint2" />
      </div>
      <div className="help has-text-grey mb-5">
        <FormattedMessage id="form--heading-hint3" />
      </div>

      {errors && errors.submit && showSubmitError(errors.submit.message)}
      <div>{submitted ? showThankYou : showForm}</div>
    </div>
  )
}

export default CourseForm
