import React from 'react'
import cx from 'classnames'
import { FormattedMessage } from 'react-intl'
import { graphql } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'

import Layout from '../components/layout'
import SEO from '../components/seo'
import Carousel from '../components/carousel'

import Collapse from '@kunukn/react-collapse'
import laptop from '../images/hero-course.jpg'
import CourseForm from '../components/form'

const CourseCard = ({ num, price, total }) => (
  <li className="cards_item">
    <div className="card">
      <div className="card_content">
        <div className="card_heading">
          <h1>
            {num} <FormattedMessage id="course--card1-price-heading" />
          </h1>
        </div>
        <div className="card_text">
          <div className="card_li odd">
            <FormattedMessage id="course--card1-price-li1" />${price}
          </div>
          <div className="card_li even">
            <FormattedMessage id="course--card1-price-li2" />${total}
          </div>
          <div className="card_li odd">
            <FormattedMessage id="course--card1-price-li3" />
          </div>
          <div className="card_li even">
            <FormattedMessage id="course--card1-price-li4" />
          </div>
          <div className="card_li odd">
            <FormattedMessage id="course--card1-price-li5" />
          </div>
          <div className="card_li even">
            <FormattedMessage id="course--card1-price-li6" />
          </div>
          <div className="card_li odd">
            <FormattedMessage id="course--card1-price-li7" />
          </div>
        </div>
        <h2 className="card_subheading">
          <FormattedMessage id="course--card1-price-subheading" />{' '}
        </h2>
      </div>
    </div>
  </li>
)

class CoursePage extends React.Component {
  state = {
    isOpen1: true,
    isOpen2: true,
    isOpen3: true,
    isOpen4: true,
    spy3: {},
  }

  render() {
    return (
      <Layout>
        <SEO title="Course" />
        <Carousel
          title={<FormattedMessage id="navbar-2" />}
          subtitle={<FormattedMessage id="course--subtitle" />}
          size={'is-medium'}
          // color={"is-primary"}
          img={laptop}
          imgalt="laptop"
        />
        <div className="main">
          <div className="app">
            <button
              className={cx('app__toggle', {
                'app__toggle--active': this.state.isOpen1,
              })}
              onClick={() => this.toggle(1)}
            >
              <span className="app__toggle-text">
                <FormattedMessage id="course--card1-heading" />
              </span>
              <div className="rotate90">
                <svg
                  className={cx('icon', {
                    'icon--expanded': this.state.isOpen1,
                  })}
                  viewBox="6 0 12 24"
                >
                  <polygon points="8 0 6 1.8 14.4 12 6 22.2 8 24 18 12" />
                </svg>
              </div>
            </button>
            <Collapse
              isOpen={this.state.isOpen1}
              className={
                'app__collapse app__collapse--gradient ' +
                (this.state.isOpen1 ? 'app__collapse--active' : '')
              }
            >
              <div className="app__content">
                <figure className="image">
                  <GatsbyImage
                    image={
                      this.props.data.course1.childImageSharp.gatsbyImageData
                    }
                    alt="course1-poster"
                  />
                </figure>
                <ul className="cards">
                  <CourseCard num="10" price="115" total="1150" />
                  <CourseCard num="20" price="105" total="2100" />
                  <CourseCard num="30" price="95" total="2850" />
                </ul>
                <a href="#registration-form">
                  <div className="is-primary reg_button">
                    <FormattedMessage id="course--button" />
                  </div>
                </a>
              </div>
            </Collapse>

            <button
              className={cx('app__toggle', {
                'app__toggle--active': this.state.isOpen2,
              })}
              onClick={() => this.toggle(2)}
            >
              <span className="app__toggle-text">
                <FormattedMessage id="course--card2-heading" />
              </span>
              <div className="rotate90">
                <svg
                  className={cx('icon', {
                    'icon--expanded': this.state.isOpen2,
                  })}
                  viewBox="6 0 12 24"
                >
                  <polygon points="8 0 6 1.8 14.4 12 6 22.2 8 24 18 12" />
                </svg>
              </div>
            </button>
            <Collapse
              isOpen={this.state.isOpen2}
              className={
                'app__collapse app__collapse--gradient ' +
                (this.state.isOpen2 ? 'app__collapse--active' : '')
              }
            >
              <div className="app__content">
                <figure
                  className="image"
                  style={{
                    textAlign: 'center',
                  }}
                >
                  <GatsbyImage
                    image={
                      this.props.data.course2.childImageSharp.gatsbyImageData
                    }
                    alt="course2-poster"
                    style={{
                      maxWidth: '500px',
                    }}
                  />
                </figure>
                <content>
                  <h3>
                    <FormattedMessage id="course--card2-content-heading" />
                  </h3>
                  <ul>
                    <li>
                      <FormattedMessage id="course--card2-content-ul1-li1" />
                    </li>
                    <li>
                      <FormattedMessage id="course--card2-content-ul1-li2" />
                    </li>

                    <li>
                      <FormattedMessage id="course--card2-content-ul1-li3" />
                    </li>
                  </ul>
                  <ul>
                    <li>
                      <FormattedMessage id="course--card2-content-ul2-li1" />
                    </li>
                    <li>
                      <FormattedMessage id="course--card2-content-ul2-li2" />
                    </li>
                    <li>
                      <FormattedMessage id="course--card2-content-ul2-li3" />
                    </li>
                    <li>
                      <FormattedMessage id="course--card2-content-ul2-li4" />
                    </li>
                  </ul>
                </content>
                <a href="#registration-form">
                  <div className="is-primary reg_button">
                    <FormattedMessage id="course--button" />
                  </div>
                </a>
              </div>
            </Collapse>
          </div>

          <CourseForm />

          <section className="section">
            <h1>
              <FormattedMessage id="course--container1-heading" />
            </h1>
            <ul>
              <li>
                <FormattedMessage id="course--container1-content-q1" /> <br />
                <FormattedMessage id="course--container1-content-a1" />
              </li>
              <li>
                <FormattedMessage id="course--container1-content-q2" /> <br />
                <FormattedMessage id="course--container1-content-a2" />
              </li>
              <li>
                <FormattedMessage id="course--container1-content-q3" /> <br />
                <FormattedMessage id="course--container1-content-a3" />
              </li>
              <li>
                <FormattedMessage id="course--container1-content-q4" /> <br />
                <FormattedMessage id="course--container1-content-a4" />
              </li>
            </ul>
          </section>
          <section>
            <h1>
              <FormattedMessage id="course--container2-heading" />
            </h1>
            <ol>
              <li>
                <FormattedMessage id="course--container2-content-p1" />
              </li>
              <li>
                <FormattedMessage id="course--container2-content-p2" />
              </li>
            </ol>
          </section>
        </div>
      </Layout>
    )
  }

  toggle = (index) => {
    let collapse = 'isOpen' + index

    this.setState((prevState) => ({ [collapse]: !prevState[collapse] }))
  }
}

export const courseImage = graphql`
  fragment courseImage on File {
    childImageSharp {
      gatsbyImageData(width: 850, layout: CONSTRAINED)
    }
  }
`

export const query = graphql`
  query {
    laptop: file(relativePath: { eq: "hero-course.jpg" }) {
      ...courseImage
    }
    course1: file(relativePath: { eq: "one-to-one.jpg" }) {
      ...courseImage
    }
    course2: file(relativePath: { eq: "group.jpg" }) {
      ...courseImage
    }
    course3: file(relativePath: { eq: "summer_english.jpeg" }) {
      ...courseImage
    }
    course4: file(relativePath: { eq: "summer_party.png" }) {
      ...courseImage
    }
  }
`

export default CoursePage
